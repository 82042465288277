import {
  CREDENTIALS_FETCH_BEGIN,
  CREDENTIALS_FETCH_FAILURE,
  CREDENTIALS_FETCH_SUCCESS,
  RESET_DASHBOARD_DATA,
  RESET_DASHBOARD_SUBJECT_LIST,
  SUBJECT_FETCH_BEGIN,
  SUBJECT_FETCH_FAILURE,
  SUBJECT_FETCH_SUCCESS,
} from "./dashBoard.constant";

const initState = {
  credentialsStats: {
    loading: false,
    data: {},
    error: "",
  },
  subjectStats: {
    loading: false,
    data: {},
    error: "",
  },
};

const dashBoardReducer = (state = initState, action) => {
  switch (action.type) {
    case CREDENTIALS_FETCH_BEGIN:
      return {
        ...state,
        credentialsStats: {
          ...state.credentialsStats,
          loading: true,
          error: "",
        },
      };
    case CREDENTIALS_FETCH_SUCCESS:
      return {
        ...state,
        credentialsStats: {
          ...state.credentialsStats,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case CREDENTIALS_FETCH_FAILURE:
      return {
        ...state,
        credentialsStats: {
          ...state.credentialsStats,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case SUBJECT_FETCH_BEGIN:
      return {
        ...state,
        subjectStats: {
          ...state?.subjectStats,
          error: "",
          loading: true,
        },
      };
    case SUBJECT_FETCH_SUCCESS: {
      const updatedList = [
        ...(state?.subjectStats?.data?.data || []),
        ...(action?.payload?.data || []),
      ].reduce(
        (acc, curr) => {
          if (acc[1].indexOf(curr.id) === -1) {
            acc[1].push(curr.id);
            acc[0].push(curr);
          }
          return acc;
        },
        [[], []]
      )[0];
      return {
        ...state,
        subjectStats: {
          loading: false,
          error: "",
          data: {
            ...(action?.payload || {}),
            data: updatedList,
          },
        },
      };
    }
    case SUBJECT_FETCH_FAILURE:
      return {
        ...state,
        subjectStats: {
          ...state?.subjectStats,
          error: action?.payload,
          loading: false,
        },
      };
    case RESET_DASHBOARD_DATA:
      return {
        ...initState,
      };
    case RESET_DASHBOARD_SUBJECT_LIST:
      return {
        ...state,
        subjectStats: { ...initState.subjectStats },
      };
    default:
      return state;
  }
};

export default dashBoardReducer;
