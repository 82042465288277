export const toBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      resolve(
        reader.result.slice(
          reader.result.indexOf(",") + 1,
          reader.result.length
        )
      );
    };
  });
};
