export const CREATE_BADGE_BEGIN = "CREATE_BADGE_BEGIN";
export const CREATE_BADGE_SUCCESS = "CREATE_BADGE_SUCESS";
export const CREATE_BADGE_FAILURE = "CREATE_BADGE_FAILURE";

export const DELETE_BADGE_BEGIN = "DELETE_BADGE_BEGIN";
export const DELETE_BADGE_SUCCESS = "DELETE_BADGE_SUCESS";
export const DELETE_BADGE_FAILURE = "DELETE_BADGE_FAILURE";

export const UPDATE_BADGE_BEGIN = "UPDATE_BADGE_BEGIN";
export const UPDATE_BADGE_SUCCESS = "UPDATE_BADGE_SUCESS";
export const UPDATE_BADGE_FAILURE = "UPDATE_BADGE_FAILURE";

export const ADD_DATAFIELD_BEGIN = "ADD_DATAFIELD_BEGIN";
export const ADD_DATAFIELD_SUCCESS = "ADD_DATAFIELD_SUCESS";
export const ADD_DATAFIELD_FAILURE = "ADD_DATAFIELD_FAILURE";

export const GET_ALL_DATAFIELDS_BEGIN = "GET_ALL_DATAFIELDS_BEGIN";
export const GET_ALL_DATAFIELDS_SUCCESS = "GET_ALL_DATAFIELDS_SUCESS";
export const GET_ALL_DATAFIELDS_FAILURE = "GET_ALL_DATAFIELDS_FAILURE";

export const DELETE_DATAFIELD_BEGIN = "DELETE_DATAFIELD_BEGIN";
export const DELETE_DATAFIELD_SUCCESS = "DELETE_DATAFIELD_SUCESS";
export const DELETE_DATAFIELD_FAILURE = "DELETE_DATAFIELD_FAILURE";

export const GET_DATAFIELD_BEGIN = "GET_DATAFIELD_BEGIN";
export const GET_DATAFIELD_SUCCESS = "GET_DATAFIELD_SUCESS";
export const GET_DATAFIELD_FAILURE = "GET_DATAFIELD_FAILURE";

export const EDIT_DATAFIELD_BEGIN = "EDIT_DATAFIELD_BEGIN";
export const EDIT_DATAFIELD_SUCCESS = "EDIT_DATAFIELD_SUCESS";
export const EDIT_DATAFIELD_FAILURE = "EDIT_DATAFIELD_FAILURE";

export const GET_THEME_BEGIN = "GET_THEME_BEGIN";
export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS";
export const GET_THEME_FAILURE = "GET_THEME_FAILURE";
export const SET_THEME = "SET_THEME";

export const CHANGE_BADGE_STATUS_BEGIN = "CHANGE_BADGE_STATUS_BEGIN";
export const CHANGE_BADGE_STATUS_SUCCESS = "CHANGE_BADGE_STATUS_SUCCESS";
export const CHANGE_BADGE_STATUS_FAILURE = "CHANGE_BADGE_STATUS_FAILURE";

export const DOWNLOAD_SAMPLE_ROSTER_FILE_BEGIN =
  "DOWNLOAD_SAMPLE_ROSTER_FILE_BEGIN";
export const DOWNLOAD_SAMPLE_ROSTER_FILE_SUCCESS =
  "DOWNLOAD_SAMPLE_ROSTER_FILE_SUCCESS";
export const DOWNLOAD_SAMPLE_ROSTER_FILE_FAILURE =
  "DOWNLOAD_SAMPLE_ROSTER_FILE_FAILURE";

export const SET_RECORD_TO_DUPLICATE = "SET_RECORD_TO_DUPLICATE";
export const SET_ALL_DATAFIELDS = "SET_ALL_DATAFIELDS";
