import {
  CREATE_BADGE_BEGIN,
  CREATE_BADGE_FAILURE,
  CREATE_BADGE_SUCCESS,
  UPDATE_BADGE_BEGIN,
  UPDATE_BADGE_FAILURE,
  UPDATE_BADGE_SUCCESS,
} from "./badge.constants";

const initialstate = {
  loading: false,
  error: "",
  response: [],
  recordForDupluicate: {},
};

export function createBadgeReducer(state = initialstate, action) {
  switch (action.type) {
    case CREATE_BADGE_BEGIN:
      return { ...state, loading: true };
    case CREATE_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CREATE_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Update BADGE
export function updateBadgeReducer(state = initialstate, action) {
  switch (action.type) {
    case UPDATE_BADGE_BEGIN:
      return { ...state, loading: true };
    case UPDATE_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
      };
    case UPDATE_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
