export const getInitials = (name) =>
  name
    .split(" ")
    .map((n) => n[0])
    .join("");

export function shortenAddress(address = "") {
  return address.slice(0, 4) + "...." + address.slice(address.length - 4);
}

export function formatAddress(address = "") {
  return address.slice(0, 8) + "........" + address.slice(address.length - 8);
}
