import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { setNewSocket } from "store/commonActions";

export default function ConnectSocket() {
  const dispatch = useDispatch();

  useEffect(() => {
    const domain = process.env.REACT_APP_SOCKET_HOST;
    const options = {
      path: "/socket.io",
      forceNew: true,
      reconnectionAttempts: 3,
      timeout: 2000,
    };
    const newSocket = io(domain, options);

    newSocket?.on("connect", () => {
      dispatch(setNewSocket(newSocket));
    });

    return () => newSocket?.close();
  }, [dispatch]);

  return null;
}
