import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Logs = lazy(() => import("./Logs"));

const logsConfig = {
  component: Logs,
  path: "/activities",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: true,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
};

export default logsConfig;
