import {
  FORGET_PASSWORD_BEGIN,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
} from "./ForgetPassword.constant";

const initState = {
  loading: false,
  data: {},
  error: "",
};

const forgetPasswordReducer = (state = initState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        data: {},
        error: "",
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FORGET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: {},
      };
    default:
      return state;
  }
};

export default forgetPasswordReducer;
