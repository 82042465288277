import { lazy } from "react";
import authRoles from "core/auth/authRoles";
const Login = lazy(() => import("./Login"));

const LoginConfig = {
  component: Login,
  path: "/",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: false,
};

export default LoginConfig;
