import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Register = lazy(() => import("./Register"));

const RegisterConfig = {
  component: Register,
  path: "/register",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: false,
};

export default RegisterConfig;
