import React from "react";
import Logo from "assets/images/svg/everycred-full-logo.svg";

const Fallback = () => {
  return (
    <div className="full-page-loader">
      <img width="200" src={Logo} alt="Everycred" />
    </div>
  );
};

export default Fallback;
