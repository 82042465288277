import makeStyles from "@material-ui/core/styles/makeStyles";
import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { objectsAreSame } from "utils";
import Footer from "./Footer/Footer";
import Header from "./Header";
import Midbar from "./Midbar/Midbar";
import { setMainContentRef } from "./Midbar/midbar.action";
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme) => ({
  mainLayoutWrapper: {
    display: "flex",
    minHeight: "100vh",
    width: "100%",
    paddingLeft: "100px",
    paddingBottom: "30px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("xl")]: {
      paddingLeft: "94px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "106px",
    },
  },
  mainContentWrapper: {
    display: "flex",
  },
  pageContentWrapper: {
    display: "flex",
    padding: "130px 30px 0px",
    width: "100%",
    backgroundColor: "#fff  ",
    [theme.breakpoints.down("sm")]: {
      padding: "100px 15px 0px",
      "&.current": {
        "& > div:first-child": {
          display: "none",
        },
        "& > div:nth-child(2)": {
          display: "block !important",
          margin: "0 15px",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "80px 0px 0px",
    },
  },
  contentWrapperBlock: {
    flex: "1 1 auto",
    minWidth: 0,
    height: "calc(100vh - 180px)",
    overflow: "hidden auto",
    scrollbarWidth: "none", // For Mozila Scroll Issue
    "&::-webkit-scrollbar": {
      display: "block !important",
      width: 0,
      height: 0,
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 140px)",
      margin: "0 15px",
    },
  },
  rightContent: {
    paddingLeft: "105px",
    marginTop: "-20%",
  },
}));

function Layout({ isChild, config, children }) {
  const { navbar, sidebar, footer, midbar } = config || {};
  const classes = useStyles();
  const dispatch = useDispatch();
  const mainContentRef = useRef(null);

  useEffect(() => {
    dispatch(setMainContentRef(mainContentRef));
  }, [mainContentRef, dispatch]);

  if (isChild) return children;

  return (
    <main className={classes.main}>
      {sidebar?.display && (
        <>
          <div className={classes.mainLayoutWrapper}>
            <Header display={navbar?.display} />
            <Sidebar display={sidebar?.display} />
            <div className={classes.pageContentWrapper} ref={mainContentRef}>
              <Midbar display={midbar?.display} />
              <div className={classes.contentWrapperBlock}>{children}</div>
            </div>
          </div>
          <Footer display={footer?.display} />
        </>
      )}
      {!sidebar?.display && <div className="pre-login-screen">{children}</div>}
    </main>
  );
}

function areEqual(prevProps, nextProps) {
  return objectsAreSame(prevProps, nextProps);
}

export default memo(Layout, areEqual);
