import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const VerifyEmail = lazy(() => import("./VerifyEmail"));

const verifyEmailConfig = {
  component: VerifyEmail,
  path: "/verify-email/:verfiyToken",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.organization,
  protected: false,
};

export default verifyEmailConfig;
