import { http, headers, uniqueArray } from "utils";
import {
  SELECT_SUBJECT_ID,
  GET_RECORDS_BEGIN,
  GET_RECORDS_SUCCESS,
  GET_RECORDS_FAILURE,
  GET_SUBJECT_LIST_BEGIN,
  GET_SUBJECT_LIST_FAILURE,
  GET_SUBJECT_LIST_SUCCESS,
  GET_MORE_SUBJECTS_BEGIN,
  GET_MORE_SUBJECTS_SUCCESS,
  GET_MORE_SUBJECTS_FAILURE,
  SET_UPDATED_SUBJECTS_LIST,
} from "./credentials.constants";

export const selectSubjectId = (payload) => ({
  type: SELECT_SUBJECT_ID,
  payload,
});

export const getRecordsBegin = () => ({
  type: GET_RECORDS_BEGIN,
});

export const getRecordsSuccess = (payload) => ({
  type: GET_RECORDS_SUCCESS,
  payload,
});
export const getRecordsFailure = (payload) => ({
  type: GET_RECORDS_FAILURE,
  payload,
});

export const getRecords = (params) => (dispatch) => {
  dispatch(getRecordsBegin());
  http(
    "get",
    "/user/issuer/subject/credential",
    null,
    {
      headers,
      params,
    },
    true
  )
    .then((response) => {
      dispatch(getRecordsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getRecordsFailure());
    });
};

//Get SUBJECT LISt

const getSubjectListBegin = () => ({
  type: GET_SUBJECT_LIST_BEGIN,
});

const getSubjectListSuccess = (payload) => ({
  type: GET_SUBJECT_LIST_SUCCESS,
  payload,
});

const getSubjectListFailure = (payload) => ({
  type: GET_SUBJECT_LIST_FAILURE,
  payload,
});

export const getSubjectList = (params, successCB) => (dispatch) => {
  dispatch(getSubjectListBegin());
  http("get", "/user/issuer/subjects", null, { headers, params }, true)
    .then((response) => {
      successCB(response.data);
      dispatch(getSubjectListSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getSubjectListFailure());
    });
};

// get more Subjects

const getMoreSubjectsBegin = () => ({
  type: GET_MORE_SUBJECTS_BEGIN,
});
const getMoreSubjectsSuccess = (payload) => ({
  type: GET_MORE_SUBJECTS_SUCCESS,
  payload,
});
const getMoreSubjectsFailure = (payload) => ({
  type: GET_MORE_SUBJECTS_FAILURE,
  payload,
});

export const getMoreSubjects = (params) => (dispatch) => {
  dispatch(getMoreSubjectsBegin);
  http("get", "/user/issuer/subjects", null, { headers, params }, true)
    .then((response) => {
      dispatch(getMoreSubjectsSuccess(response.data.data.data));
    })
    .catch((err) => {
      dispatch(getMoreSubjectsFailure());
    });
};

const setUpatedMidbarList = (payload) => ({
  type: SET_UPDATED_SUBJECTS_LIST,
  payload,
});

export const getSubjectDetails = (params) => (dispatch, getState) => {
  const sujectList =
    getState().credentials?.subjectList?.response?.data?.data || [];
  http("get", `/user/subject/stats`, null, { headers, params }, true).then(
    (response) => {
      let index = sujectList.findIndex(
        (element) => element.id === +params.subject_id
      );
      let subjectDetails = sujectList?.[index] || {};
      let newArr = [...sujectList];
      newArr[index] = { ...subjectDetails, ...response.data.data };

      const payload = {
        ...getState().credentials?.subjectList?.response?.data,
        data: uniqueArray(newArr, Object.keys(newArr[0])),
      };

      dispatch(setUpatedMidbarList(payload));
    }
  );
};
