import {
  VERIFY_EMAIL_BEGIN,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
} from "./verfiyEmail.constant";

const initState = {
  response: "",
  error: "",
  isEmailVerified: false,
  loading: false,
};

export default function verifyEmailReducer(state = initState, action) {
  switch (action.type) {
    case VERIFY_EMAIL_BEGIN:
      return {
        ...state,
        loading: true,
        isEmailVerified: false,
        error: "",
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        isEmailVerified: true,
        error: "",
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        isEmailVerified: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
