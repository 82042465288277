export const GET_SUBJECT_INFO_BEGIN = "GET_SUBJECT_INFO_BEGIN";
export const GET_SUBJECT_INFO_SUCCESS = "GET_SUBJECT_INFO_SUCESS";
export const GET_SUBJECT_INFO_FAILURE = "GET_SUBJECT_INFO_FAILURE";

export const SET_SUBJECT_INFO = "SET_SUBJECT_INFO";

export const GET_CREDENTIAL_LIST_FOR_SUBJECT_BEGIN =
  "GET_CREDENTIAL_LIST_FOR_SUBJECT_BEGIN";
export const GET_CREDENTIAL_LIST_FOR_SUBJECT_SUCCESS =
  "GET_CREDENTIAL_LIST_FOR_SUBJECT_SUCESS";
export const GET_CREDENTIAL_LIST_FOR_SUBJECT_FAILURE =
  "GET_CREDENTIAL_LIST_FOR_SUBJECT_FAILURE";

export const ADD_RECORD_FOR_SUBJECT_BEGIN = "ADD_RECORD_FOR_SUBJECT_BEGIN";
export const ADD_RECORD_FOR_SUBJECT_SUCCESS = "ADD_RECORD_FOR_SUBJECT_SUCESS";
export const ADD_RECORD_FOR_SUBJECT_FAILURE = "ADD_RECORD_FOR_SUBJECT_FAILURE";

export const UPLOAD_RECORD_FOR_SUBJECT_BEGIN =
  "UPLOAD_RECORD_FOR_SUBJECT_BEGIN";
export const UPLOAD_RECORD_FOR_SUBJECT_SUCCESS =
  "UPLOAD_RECORD_FOR_SUBJECT_SUCESS";
export const UPLOAD_RECORD_FOR_SUBJECT_FAILURE =
  "UPLOAD_RECORD_FOR_SUBJECT_FAILURE";

export const GET_ALL_RECORD_FOR_SUBJECT_BEGIN =
  "GET_ALL_RECORD_FOR_SUBJECT_BEGIN";
export const GET_ALL_RECORD_FOR_SUBJECT_SUCCESS =
  "GET_ALL_RECORD_FOR_SUBJECT_SUCESS";
export const GET_ALL_RECORD_FOR_SUBJECT_FAILURE =
  "GET_ALL_RECORD_FOR_SUBJECT_FAILURE";

export const GET_ISSUER_LIST_BEGIN = "GET_ISSUER_LIST_BEGIN";
export const GET_ISSUER_LIST_SUCCESS = "GET_ISSUER_LIST_SUCESS";
export const GET_ISSUER_LIST_FAILURE = "GET_ISSUER_LIST_FAILURE";
