export const CREATE_SUBJECT_BEGIN = "CREATE_SUBJECT_BEGIN";
export const CREATE_SUBJECT_SUCCESS = "CREATE_SUBJECT_SUCESS";
export const CREATE_SUBJECT_FAILURE = "CREATE_SUBJECT_FAILURE";

export const DELETE_SUBJECT_BEGIN = "DELETE_SUBJECT_BEGIN";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCESS";
export const DELETE_SUBJECT_FAILURE = "DELETE_SUBJECT_FAILURE";

export const UPDATE_SUBJECT_BEGIN = "UPDATE_SUBJECT_BEGIN";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCESS";
export const UPDATE_SUBJECT_FAILURE = "UPDATE_SUBJECT_FAILURE";

export const ADD_DATAFIELD_BEGIN = "ADD_DATAFIELD_BEGIN";
export const ADD_DATAFIELD_SUCCESS = "ADD_DATAFIELD_SUCESS";
export const ADD_DATAFIELD_FAILURE = "ADD_DATAFIELD_FAILURE";

export const GET_ALL_DATAFIELDS_BEGIN = "GET_ALL_DATAFIELDS_BEGIN";
export const GET_ALL_DATAFIELDS_SUCCESS = "GET_ALL_DATAFIELDS_SUCESS";
export const GET_ALL_DATAFIELDS_FAILURE = "GET_ALL_DATAFIELDS_FAILURE";

export const DELETE_DATAFIELD_BEGIN = "DELETE_DATAFIELD_BEGIN";
export const DELETE_DATAFIELD_SUCCESS = "DELETE_DATAFIELD_SUCESS";
export const DELETE_DATAFIELD_FAILURE = "DELETE_DATAFIELD_FAILURE";

export const GET_DATAFIELD_BEGIN = "GET_DATAFIELD_BEGIN";
export const GET_DATAFIELD_SUCCESS = "GET_DATAFIELD_SUCESS";
export const GET_DATAFIELD_FAILURE = "GET_DATAFIELD_FAILURE";

export const EDIT_DATAFIELD_BEGIN = "EDIT_DATAFIELD_BEGIN";
export const EDIT_DATAFIELD_SUCCESS = "EDIT_DATAFIELD_SUCESS";
export const EDIT_DATAFIELD_FAILURE = "EDIT_DATAFIELD_FAILURE";

export const GET_THEME_BEGIN = "GET_THEME_BEGIN";
export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS";
export const GET_THEME_FAILURE = "GET_THEME_FAILURE";
export const SET_THEME = "SET_THEME";

export const CHANGE_SUBJECT_STATUS_BEGIN = "CHANGE_SUBJECT_STATUS_BEGIN";
export const CHANGE_SUBJECT_STATUS_SUCCESS = "CHANGE_SUBJECT_STATUS_SUCCESS";
export const CHANGE_SUBJECT_STATUS_FAILURE = "CHANGE_SUBJECT_STATUS_FAILURE";

export const DOWNLOAD_SAMPLE_ROSTER_FILE_BEGIN =
  "DOWNLOAD_SAMPLE_ROSTER_FILE_BEGIN";
export const DOWNLOAD_SAMPLE_ROSTER_FILE_SUCCESS =
  "DOWNLOAD_SAMPLE_ROSTER_FILE_SUCCESS";
export const DOWNLOAD_SAMPLE_ROSTER_FILE_FAILURE =
  "DOWNLOAD_SAMPLE_ROSTER_FILE_FAILURE";

export const SET_RECORD_TO_DUPLICATE = "SET_RECORD_TO_DUPLICATE";
export const SET_ALL_DATAFIELDS = "SET_ALL_DATAFIELDS";
