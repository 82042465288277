import { createTheme } from "@material-ui/core/styles";

const themeBreakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 1025,
      lg: 1441,
      xl: 1680,
    },
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: themeBreakpoints.breakpoints.values.xs,
      sm: themeBreakpoints.breakpoints.values.sm,
      md: themeBreakpoints.breakpoints.values.md,
      lg: themeBreakpoints.breakpoints.values.lg,
      xl: themeBreakpoints.breakpoints.values.xl,
    },
  },
  palette: {
    primary: {
      main: "#083f85",
    },
    secondary: {
      main: "#616161",
    },
    white: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        marginBottom: 6,
        backgroundColor: "#f6f6f6",
        borderRadius: "6px",
        "&.MuiOutlinedInput-multiline": {
          padding: "0 14px 0 0",
        },
        "& .MuiInputBase-input": {
          boxSizing: "border-box",
          fontSize: "16px",
          color: "#616161",
          padding: "10px 15px",
        },
        "& Input": {
          height: "40px",
          [themeBreakpoints.breakpoints.down("xl")]: {
            height: "35px",
          },
        },
        "& textarea": {
          height: "115px",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          margin: 0,
        },
      },
    },
    MuiButton: {
      root: {
        padding: "11px 0",
        borderRadius: "27px",
        textTransform: "Capitalize",
        fontWeight: "600",
        fontSize: "14px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#616161",
        fontSize: "16px",
        opacity: ".9",
        marginBottom: "8px",
      },
    },
    MuiSelect: {
      // backgroundColor: "#f6f6f6",
    },
    MuiAlert: {
      root: {
        alignItems: "center",
      },
    },
    MuiCssBaseline: {
      "@global": {},
    },
    MuiTableRow: {
      root: {
        height: "60px",
        "&.Mui-selected": {
          background: "none",
          "&:hover": {
            background: "none",
          },
        },
      },
    },
    MuiTablePagination: {
      root: {
        display: "flex",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px 10px 0 0",
        fontSize: "13px",
        "&:first-child": {
          paddingLeft: 20,
        },
      },
      paddingCheckbox: {
        width: "60px",
        MuiIconButton: {
          colorSecondary: {
            "&:hover": {
              background: "none",
            },
            "&:checked": {
              color: "#083f85",
            },
          },
        },
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: "flex-end",
      },
    },
    MuiPaginationItem: {
      root: {
        height: "33px",
        padding: "0 3px",
        margin: "0",
        borderRadius: "50%",
      },
      icon: {
        fill: "#616161",
        backgroundColor: "#ececec",
        borderRadius: "50%",
        fontSize: "25px",
      },
      page: {
        "&.Mui-selected": {
          backgroundColor: "#083f85",
          color: "#fff",
        },
      },
    },
    MuiDialog: {
      root: {
        "& .MuiDialog-paper": {
          borderRadius: "10",
        },
      },
    },
    MuiTabs: {
      root: {
        overflow: "auto",
      },
      fixed: {
        overflowX: "auto",
        overflow: "auto !important",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      },
      flexContainer:{
        justifyContent:"space-between"
      }
    },
    PrivateTabIndicator: {
      root: {
        height: "2px !important",
        borderRadius: 100,
        position: "absolute",
        background:
          "linear-gradient(to right, #f6f6f6 43.33%, #083f85 33.33%, #083f85 59.66%, #f6f6f6 46.66%)",
      },
      colorSecondary: {
        borderRadius: 100,
        backgroundColor: "#616161",
        [themeBreakpoints.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      vertical: {
        right: -8,
        top: "17px !important",
        width: 25,
        borderRadius: 25,
      },
    },
  },
  typography: {
    fontFamily: "Roboto, Arial",
    h1: {
      fontSize: "46px",
      fontWeight: "700",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 26,
      },
    },
    h2: {
      fontSize: "36px",
      fontWeight: "700",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: "32px",
      fontWeight: "700",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 22,
      },
    },
    h4: {
      fontSize: 28,
      fontWeight: "700",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
    h5: {
      fontSize: "26px",
      fontWeight: "700",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    h6: {
      fontSize: "22px",
      fontWeight: "700",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    subtitle1: {
      fontSize: "20px",
      fontWeight: "500",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    subtitle2: {
      fontSize: "16px",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    body1: {
      fontSize: "14px",
      fontWeight: "400",
      [themeBreakpoints.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    body2: {
      fontSize: "12px",
      fontWeight: "400",
    },
  },
});

export default theme;
