import Fallback from "components/Fallback";
import { getUserDetails } from "components/layout/Header/header.action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCookie } from "utils";

export default function AuthGuard({ children, pageProps }) {
  const userDetails = useSelector((state) => state.getUser.data);
  const { canShow } = pageProps;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userDetails.name && getCookie("accessToken")) {
      dispatch(getUserDetails());
    }
  }, [dispatch, userDetails.name]);

  if (!pageProps.protected) return children;
  if (!userDetails?.name) return <Fallback />;
  // eslint-disable-next-line no-eval
  if (typeof canShow === "string" ? eval(canShow) : true) return children;

  return (
    <Redirect
      to={{
        pathname: userDetails?.is_plan_active ? "/dashboard" : "/pricing",
      }}
    />
  );
}
