export const GET_BADGE_INFO_BEGIN = "GET_BADGE_INFO_BEGIN";
export const GET_BADGE_INFO_SUCCESS = "GET_BADGE_INFO_SUCESS";
export const GET_BADGE_INFO_FAILURE = "GET_BADGE_INFO_FAILURE";

export const SET_BADGE_INFO = "SET_BADGE_INFO";

export const GET_CREDENTIAL_LIST_FOR_BADGE_BEGIN =
  "GET_CREDENTIAL_LIST_FOR_BADGE_BEGIN";
export const GET_CREDENTIAL_LIST_FOR_BADGE_SUCCESS =
  "GET_CREDENTIAL_LIST_FOR_BADGE_SUCESS";
export const GET_CREDENTIAL_LIST_FOR_BADGE_FAILURE =
  "GET_CREDENTIAL_LIST_FOR_BADGE_FAILURE";

export const ADD_RECORD_FOR_BADGE_BEGIN = "ADD_RECORD_FOR_BADGE_BEGIN";
export const ADD_RECORD_FOR_BADGE_SUCCESS = "ADD_RECORD_FOR_BADGE_SUCESS";
export const ADD_RECORD_FOR_BADGE_FAILURE = "ADD_RECORD_FOR_BADGE_FAILURE";

export const UPLOAD_RECORD_FOR_BADGE_BEGIN = "UPLOAD_RECORD_FOR_BADGE_BEGIN";
export const UPLOAD_RECORD_FOR_BADGE_SUCCESS = "UPLOAD_RECORD_FOR_BADGE_SUCESS";
export const UPLOAD_RECORD_FOR_BADGE_FAILURE =
  "UPLOAD_RECORD_FOR_BADGE_FAILURE";

export const GET_ALL_RECORD_FOR_BADGE_BEGIN = "GET_ALL_RECORD_FOR_BADGE_BEGIN";
export const GET_ALL_RECORD_FOR_BADGE_SUCCESS =
  "GET_ALL_RECORD_FOR_BADGE_SUCESS";
export const GET_ALL_RECORD_FOR_BADGE_FAILURE =
  "GET_ALL_RECORD_FOR_BADGE_FAILURE";

export const GET_ISSUER_LIST_BEGIN = "GET_ISSUER_LIST_BEGIN";
export const GET_ISSUER_LIST_SUCCESS = "GET_ISSUER_LIST_SUCESS";
export const GET_ISSUER_LIST_FAILURE = "GET_ISSUER_LIST_FAILURE";
