import {
  SET_HOLDER_DETAILS,
  GET_HOLDER_CREDENTIALS_BEGIN,
  GET_HOLDER_CREDENTIALS_SUCCESS,
  GET_HOLDER_CREDENTIALS_FAILURE,
  SET_SEARCH_HOLDER_DETAILS,
  SET_SEARCH_PARAMS,
} from "./holders.constant";

const initState = {
  holderDetails: {},
  holderSearchDetails: {
    search: "",
    search_by: "",
  },
  credentialsList: {
    loading: false,
    response: {},
    error: "",
  },
};

export const holderReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_HOLDER_DETAILS:
      return { ...state, holderDetails: payload };
    case SET_SEARCH_HOLDER_DETAILS:
      return { ...state, holderSearchDetails: payload };
    case SET_SEARCH_PARAMS:
      return { ...state, holderSearchParam: payload };
    case GET_HOLDER_CREDENTIALS_BEGIN:
      return {
        ...state,
        credentialsList: {
          loading: true,
        },
      };

    case GET_HOLDER_CREDENTIALS_SUCCESS:
      return {
        ...state,
        credentialsList: {
          loading: false,
          response: payload,
        },
      };

    case GET_HOLDER_CREDENTIALS_FAILURE:
      return {
        ...state,
        credentialsList: {
          loading: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};
