import {
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_EMAIL_VERIFICATION_BEGIN,
  REGISTER_EMAIL_VERIFICATION_FAILURE,
  REGISTER_EMAIL_VERIFICATION_SUCCESS,
  TOGGLE_EMAIL_VERIFICATION,
} from "./register.constants";

const initialState = {
  loading: false,
  error: "",
  response: {},
  sendVerificationLink: {
    loading: false,
    error: "",
    response: "",
  },
  isEmailVerified: false,
};

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_BEGIN:
      return { ...state, loading: true };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REGISTER_EMAIL_VERIFICATION_BEGIN:
      return {
        ...state,
        sendVerificationLink: { ...state.sendVerificationLink, loading: true },
      };
    case REGISTER_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        sendVerificationLink: {
          ...state.sendVerificationLink,
          loading: false,
          response: action.payload,
        },
      };
    case REGISTER_EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        sendVerificationLink: {
          ...state.sendVerificationLink,
          loading: false,
          error: action.payload,
        },
      };
    case TOGGLE_EMAIL_VERIFICATION:
      return {
        ...state,
        isEmailVerified: action.payload,
      };
    default:
      return state;
  }
}
