export const SELECT_SUBJECT_ID = "SELECTED_SUBJECT_ID";
export const GET_RECORDS_BEGIN = "GET_RECORDS_BEGIN";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const GET_SUBJECT_LIST_BEGIN = "GET_SUBJECT_LIST_BEGIN";
export const GET_SUBJECT_LIST_SUCCESS = "GET_SUBJECT_LIST_SUCCESS";
export const GET_SUBJECT_LIST_FAILURE = "GET_SUBJECT_LIST_FAILURE";

export const GET_MORE_SUBJECTS_BEGIN = "GET_MORE_SUBJECTS_BEGIN";
export const GET_MORE_SUBJECTS_SUCCESS = "GET_MORE_SUBJECTS_SUCCESS";
export const GET_MORE_SUBJECTS_FAILURE = "GET_MORE_SUBJECTS_FAILURE";

export const SET_UPDATED_SUBJECTS_LIST = "SET_UPDATED_SUBJECTS_LIST";
