import makeStyles from "@material-ui/core/styles/makeStyles";
import Logo from "assets/images/svg/everycred-full-logo.svg";

const AppLogo = ({ customClass = "" }) => {
  const useStyles = makeStyles((theme) => ({
    mainLogo: {
      width: "273px",
      margin: "0 auto 20px",
      "&.inner-page-logo": {
        width: "175px",
        margin: 0,
        "& img": {
          width: "100%",
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={`${classes.mainLogo} ${customClass}`}>
      <img alt="App Logo" src={Logo} width="274" height="46" />
    </div>
  );
};

export default AppLogo;
