export const CREATE_ISSUER_BEGIN = "CREATE_ISSUER_BEGIN";
export const CREATE_ISSUER_SUCCESS = "CREATE_ISSUER_SUCCESS";
export const CREATE_ISSUER_FAILURE = "CREATE_ISSUER_FAILURE";
export const SET_ISSUER_DETAILS = "SET_ISSUER_DETAILS";

export const GET_ALL_CREDENTIALS_BEGIN = "GET_ALL_CREDENTIALS_BEGIN";
export const GET_ALL_CREDENTIALS_SUCCESS = "GET_ALL_CREDENTIALS_SUCCESS";
export const GET_ALL_CREDENTIALS_FAILURE = "GET_ALL_CREDENTIALS_FAILURE";

export const GET_SEARCH_CREDENTIALS_BEGIN = "GET_ALL_CREDENTIALS_BEGIN";
export const GET_SEARCH_CREDENTIALS_SUCCESS = "GET_ALL_CREDENTIALS_SUCCESS";
export const GET_SEARCH_CREDENTIALS_FAILURE = "GET_ALL_CREDENTIALS_FAILURE";

export const UPDATE_ISSUER_BEGIN = "UPDATE_ISSUER_BEGIN";
export const UPDATE_ISSUER_SUCCESS = "UPDATE_ISSUER_SUCCESS";
export const UPDATE_ISSUER_FAILURE = "UPDATE_ISSUER_FAILURE";

export const CLEAR_ISSUER_DATA = "CLEAR_ISSUER_DATA";

export const DELETE_ISSUER_BEGIN = "DELETE_ISSUER_BEGIN";
export const DELETE_ISSUER_SUCCESS = "DELETE_ISSUER_SUCCESS";
export const DELETE_ISSUER_FAILURE = "DELETE_ISSUER_FAILURE";

export const GET_ISSUER_STATS_BEGIN = "GET_ISSUER_STATS_BEGIN";
export const GET_ISSUER_STATS_SUCCESS = "GET_ISSUER_STATS_SUCCESS";
export const GET_ISSUER_STATS_FAILURE = "GET_ISSUER_STATS_FAILURE";

export const SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN =
  "SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN";
export const SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS =
  "SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS";
export const SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE =
  "SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE";

export const GET_EMAIL_VERIFICATION_STATUS = "GET_EMAIL_VERIFICATION_STATUS";
export const TOGGLE_EMAIL_VERIFICATION_STATUS =
  "TOGGLE_EMAIL_VERIFICATION_STATUS";
