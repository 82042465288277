export const GET_USER_DETAILS_BEGIN = "GET_USER_DETAILS_BEGIN";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const GET_RECENTLY_VERIFIED_RECORD_BEGIN =
  "GET_RECENTLY_VERIFIED_RECORD_BEGIN";
export const GET_RECENTLY_VERIFIED_RECORD_SUCCESS =
  "GET_RECENTLY_VERIFIED_RECORD_SUCCESS";
export const GET_RECENTLY_VERIFIED_RECORD_FAILURE =
  "GET_RECENTLY_VERIFIED_RECORD_FAILURE";

export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const GET_NOTIFICATION_LIST_BEGIN = "GET_NOTIFICATION_LIST_BEGIN";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_FAILURE = "GET_NOTIFICATION_LIST_FAILURE";
export const SET_PARAMETER = "SET_PARAMETER";
export const READ_NOTIFICATIONS_BEGIN = "READ_NOTIFICATIONS_BEGIN";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAILURE = "READ_NOTIFICATIONS_FAILURE";
