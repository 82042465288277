import { lazy } from "react";
import authRoles from "core/auth/authRoles";
const NoMatch = lazy(() => import("./NoMatch"));

const noMatchConfig = {
  component: NoMatch,
  path: "*",
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
};

export default noMatchConfig;
