import { lazy } from "react";
import authRoles from "core/auth/authRoles";

const DashBoard = lazy(() => import("./DashBoard"));

const homeConfig = {
  component: DashBoard,
  path: "/dashboard",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
};

export default homeConfig;
