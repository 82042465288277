import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Heading from "./components/Heading";
import Action from "./components/Action";
import Body from "./components/Body";

const useStyles = makeStyles((theme) => ({
  midbarContentWrapper: {
    position: "relative",
    height: "calc(100vh - 160px)",
    overflow: "auto",
    top: "0",
    backgroundColor: " #f6f6f6",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    marginRight: "30px",
    scrollbarWidth: "none",
    [theme.breakpoints.down("xl")]: {
      width: "320px",
      flex: "0 0 320px",
      padding: "20px 0 10px 20px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "20px 0 10px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: "auto",
      margin: 0,
      borderRadius: "14px 14px 0 0",
      padding: "25px 20px",
      height: "calc(100vh - 137px)",
    },
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 118px)",
    },
    [theme.breakpoints.up("xl")]: {
      width: "424px",
      flex: "0 0 424px",
      padding: "10px 0 10px 32px",
    },
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transparent",
    },
    "& + div": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));

function Midbar({ display }) {
  const classes = useStyles();

  if (!display) return null;

  return (
    <div className={classes.midbarContentWrapper}>
      <div>
        <Heading />
        <Action />
      </div>
      <Body />
    </div>
  );
}
export default React.memo(Midbar);
