import { setSearchHolderDetails } from "pages/Holders/holders.action";
import {
  LIST_FETCH_BEGIN,
  LIST_FETCH_SUCCESS,
  LIST_FETCH_FAILURE,
  UPDATE_MIDBAR_LIST,
  CHANGE_ORDER_BY,
  FETCH_MORE_LIST_ITEM_BEGIN,
  FETCH_MORE_LIST_ITEM_SUCCESS,
  FETCH_MORE_LIST_ITEM_FAILURE,
  CHANGE_PAGE,
  CHANGE_ROW_PER_PAGE,
  SET_MIDBAR_PARENT_REF,
  SET_MAIN_CONTENT_REF,
} from "./midbar.constant";
import { http, headers, uniqueArray } from "utils";

const redirectForMobile = (data, path, pathname, history, width) => {
  if (data?.[0] && width !== "xs" && width !== "sm") {
    let { id } = data[0];
    if (pathname.includes("/credentials") && !pathname.includes("/analytics")) {
      history.push(`${path}/credential-details/${id}`);
    } else if (
      pathname.includes("/issuers") &&
      !pathname.includes("/issuers/new-issuer") &&
      !pathname.includes("/update") &&
      !pathname.includes("/analytics") &&
      !pathname.includes("/view")
    ) {
      history.push(`/issuers/issuer-details/${id}`);
    } else if (
      pathname.includes("/subjects") &&
      !pathname.includes("/subjects/new-subject") &&
      !pathname.includes("/update") &&
      !pathname.includes("/analytics") &&
      !pathname.includes("/view")
    ) {
      history.push(`/subjects/subject-details/${id}`);
    } else if (
      pathname.includes("/badges") &&
      !pathname.includes("/badges/new-badges") &&
      !pathname.includes("/update") &&
      !pathname.includes("/analytics") &&
      !pathname.includes("/view")
    ) {
      history.push(`/badges/badge-details/${id}`);
    } else if (
      pathname.includes("/holders") &&
      !pathname.includes("/view") &&
      !pathname.includes("/analytics") &&
      !pathname.includes("/view")
    ) {
      history.push(`/holders/holder-details/${id}`);
    } else if (pathname.includes("/activities")) {
      history.push(`${path}/activities-details/${id}`);
    }
  }
};

const redirect = (data, path, pathname, history, width) => {
  if (data?.[0]) {
    let { id } = data[0];
    if (pathname === "/credentials") {
      history.push(`${path}/credential-details/${id}`);
    } else if (pathname === "/issuers") {
      history.push(`${path}/issuer-details/${id}`);
    } else if (pathname === "/subjects") {
      history.push(`${path}/subject-details/${id}`);
    } else if (pathname === "/badges") {
      history.push(`${path}/badge-details/${id}`);
    } else if (pathname === "/holders") {
      history.push(`${path}/holder-details/${id}`);
    } else if (pathname === "/activities") {
      history.push(`${path}/activities-details/${id}`);
    }
  }
  redirectForMobile(data, path, pathname, history, width);
};

export const changePage = (payload) => ({ type: CHANGE_PAGE, payload });
export const ChangeRowPerPage = (payload) => ({
  type: CHANGE_ROW_PER_PAGE,
  payload,
});

const listFetchBegin = () => ({ type: LIST_FETCH_BEGIN });

export const listFetchSuccess = (payload) => ({
  type: LIST_FETCH_SUCCESS,
  payload,
});

const listFetchFailure = (payload) => ({
  type: LIST_FETCH_FAILURE,
  payload,
});

export const getList =
  (url, params, path, pathname, history, width) => (dispatch) => {
    dispatch(listFetchBegin());
    dispatch(changePage(1));
    http("get", url, null, { headers, params }, true)
      .then((response) => {
        let data = {
          list: [...new Set(response.data.data.data)],
          pages: response.data.data.pages || 1,
          total: response.data.data.total || 1,
        };

        dispatch(listFetchSuccess(data));
        if (path) {
          redirect(response.data.data.data, path, pathname, history, width);
        }
      })
      .catch((err) => {
        dispatch(listFetchFailure(err.response?.data?.message));
      });
  };

export const updateMidbarList = (payload) => ({
  type: UPDATE_MIDBAR_LIST,
  payload,
});

export const changeOrderBy = (payload) => ({
  type: CHANGE_ORDER_BY,
  payload,
});

// Fetch more item

const fetchMoreListItemBegin = () => ({
  type: FETCH_MORE_LIST_ITEM_BEGIN,
});
const fetchMoreListItemSuccess = (payload) => ({
  type: FETCH_MORE_LIST_ITEM_SUCCESS,
  payload,
});
const fetchMoreListItemFailure = (payload) => ({
  type: FETCH_MORE_LIST_ITEM_FAILURE,
  payload,
});

export const getMoreListItem = (url, params) => (dispatch, getState) => {
  let { data } = getState().midBar;
  dispatch(fetchMoreListItemBegin());
  http("get", url, null, { headers, params }, true)
    .then((response) => {
      let newArray = uniqueArray(
        [...data.list, ...response.data.data.data],
        Object.keys(response.data.data.data[0])
      );
      let payload = {
        list: newArray || [],
        pages: response.data.data.pages || 1,
        total: response.data.data.total || 1,
      };
      dispatch(fetchMoreListItemSuccess(payload));
      dispatch(setSearchHolderDetails({ search: "", search_by: "" }));
    })
    .catch((error) => {
      dispatch(fetchMoreListItemFailure(error.response?.data?.message));
      dispatch(setSearchHolderDetails({ search: "", search_by: "" }));
    });
};

export const setMidbarParentRef = (payload) => ({
  type: SET_MIDBAR_PARENT_REF,
  payload,
});

export const setMainContentRef = (payload) => ({
  type: SET_MAIN_CONTENT_REF,
  payload,
});
