/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  organization: ["admin", "business"],
  user: ["admin", "business", "holer"],
  onlyGuest: ["guest"],
  anyOne: ["admin", "business", "holer", "guest"],
};

export default authRoles;
