import {
  UPDATE_USER_PROFILE_BEGIN,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_SUCCESS,
  CHANGE_USER_PASSWORD_BEGIN,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  CLEAR_UPDATE_USER_PROFILE_RESPONSE,
} from "./settings.constant";

const initialstate = {
  loading: false,
  error: "",
  response: {},
};

export function updateUserProfileReducer(state = initialstate, action) {
  switch (action.type) {
    case UPDATE_USER_PROFILE_BEGIN:
      return { ...state, loading: true };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_UPDATE_USER_PROFILE_RESPONSE:
      return {
        ...state,
        loading: false,
        response: "",
      };
    default:
      return state;
  }
}

export function changeUserPasswordReducer(state = initialstate, action) {
  switch (action.type) {
    case CHANGE_USER_PASSWORD_BEGIN:
      return { ...state, loading: true };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
