import Layout from "components/layout";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import store from "store";
import setupInterceptors, { getCookie } from "utils";
import defaultRoute from "./defaultRoute";
import AuthGuard from "components/AuthGuard";

function AppRoute(route) {
  const token = getCookie("accessToken");
  const isAuthenticated = !route.protected || Boolean(token);

  setupInterceptors(store, useHistory());

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) =>
        !isAuthenticated ? (
          <Redirect
            to={{
              pathname: defaultRoute,
              state: { from: props.location },
            }}
          />
        ) : (
          <AuthGuard routerProps={props} pageProps={route}>
            <Layout isChild={route.isChild} config={route?.layout?.config}>
              {route.routes ? (
                <>
                  <route.component
                    {...props}
                    routes={route.routes}
                    auth={route.auth}
                  />
                  <Switch>
                    {route.routes.map((subroute) => (
                      <AppRoute
                        key={subroute.path}
                        auth={route.auth}
                        isChild={true}
                        {...subroute}
                      />
                    ))}
                  </Switch>
                </>
              ) : (
                <route.component {...props} auth={route.auth} />
              )}
            </Layout>
          </AuthGuard>
        )
      }
    />
  );
}

export default AppRoute;
