export const CREDENTIALS_FETCH_BEGIN = "CREDENTIALS_FETCH_BEGIN";
export const CREDENTIALS_FETCH_SUCCESS = "CREDENTIALS_FETCH_SUCCESS";
export const CREDENTIALS_FETCH_FAILURE = "CREDENTIALS_FETCH_FAILURE";

export const SUBJECT_FETCH_BEGIN = "SUBJECT_FETCH_BEGIN";
export const SUBJECT_FETCH_SUCCESS = "SUBJECT_FETCH_SUCCESS";
export const SUBJECT_FETCH_FAILURE = "SUBJECT_FETCH_FAILURE";

export const RESET_DASHBOARD_DATA = "RESET_DASHBOARD_DATA";
export const RESET_DASHBOARD_SUBJECT_LIST = "RESET_DASHBOARD_SUBJECT_LIST";

