import {
  CREATE_SUBJECT_BEGIN,
  CREATE_SUBJECT_SUCCESS,
  CREATE_SUBJECT_FAILURE,
  DELETE_SUBJECT_BEGIN,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAILURE,
  UPDATE_SUBJECT_BEGIN,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAILURE,
  ADD_DATAFIELD_BEGIN,
  ADD_DATAFIELD_SUCCESS,
  ADD_DATAFIELD_FAILURE,
  GET_ALL_DATAFIELDS_BEGIN,
  GET_ALL_DATAFIELDS_SUCCESS,
  GET_ALL_DATAFIELDS_FAILURE,
  DELETE_DATAFIELD_BEGIN,
  DELETE_DATAFIELD_SUCCESS,
  DELETE_DATAFIELD_FAILURE,
  GET_DATAFIELD_BEGIN,
  GET_DATAFIELD_SUCCESS,
  GET_DATAFIELD_FAILURE,
  EDIT_DATAFIELD_BEGIN,
  EDIT_DATAFIELD_SUCCESS,
  EDIT_DATAFIELD_FAILURE,
  GET_THEME_BEGIN,
  GET_THEME_SUCCESS,
  GET_THEME_FAILURE,
  SET_THEME,
  CHANGE_SUBJECT_STATUS_BEGIN,
  CHANGE_SUBJECT_STATUS_SUCCESS,
  CHANGE_SUBJECT_STATUS_FAILURE,
  DOWNLOAD_SAMPLE_ROSTER_FILE_BEGIN,
  DOWNLOAD_SAMPLE_ROSTER_FILE_SUCCESS,
  DOWNLOAD_SAMPLE_ROSTER_FILE_FAILURE,
  SET_RECORD_TO_DUPLICATE,
  SET_ALL_DATAFIELDS,
} from "./subject.constants";

const initialstate = {
  loading: false,
  error: "",
  response: [],
  recordForDupluicate: {},
};

// Create Subject Reducer

export function createSubjectReducer(state = initialstate, action) {
  switch (action.type) {
    case CREATE_SUBJECT_BEGIN:
      return { ...state, loading: true };
    case CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CREATE_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Update Subject
export function updateSubjectReducer(state = initialstate, action) {
  switch (action.type) {
    case UPDATE_SUBJECT_BEGIN:
      return { ...state, loading: true };
    case UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
      };
    case UPDATE_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Delete Subject
export function deleteSubjectReducer(state = initialstate, action) {
  switch (action.type) {
    case DELETE_SUBJECT_BEGIN:
      return { ...state, loading: true };
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Add (Create) Datafield Reducer

export function addDataFieldReducer(state = initialstate, action) {
  switch (action.type) {
    case ADD_DATAFIELD_BEGIN:
      return { ...state, loading: true };
    case ADD_DATAFIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_DATAFIELD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function getAllDatafieldsReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_ALL_DATAFIELDS_BEGIN:
      return { ...state, loading: true };
    case GET_ALL_DATAFIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_ALL_DATAFIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_RECORD_TO_DUPLICATE:
      return {
        ...state,
        recordForDupluicate: action.payload,
      };
    case SET_ALL_DATAFIELDS:
      return { ...state, response: action.payload };
    default:
      return state;
  }
}

export function deleteDataFieldReducer(state = initialstate, action) {
  switch (action.type) {
    case DELETE_DATAFIELD_BEGIN:
      return { ...state, loading: true, response: {} };
    case DELETE_DATAFIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: "",
      };
    case DELETE_DATAFIELD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: {},
      };
    default:
      return state;
  }
}

// Get Datafield

export function getDataFieldReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_DATAFIELD_BEGIN:
      return { ...state, loading: true, response: [], error: "" };
    case GET_DATAFIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: "",
      };
    case GET_DATAFIELD_FAILURE:
      return {
        ...state,
        loading: false,
        response: [],
        error: action.payload,
      };
    default:
      return state;
  }
}

// Edit Datafield

export function editDataFieldReducer(state = initialstate, action) {
  switch (action.type) {
    case EDIT_DATAFIELD_BEGIN:
      return { ...state, loading: true };
    case EDIT_DATAFIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case EDIT_DATAFIELD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

//Get Theme

const themeInitState = {
  loading: false,
  error: "",
  response: [],
  selectedTheme: 1,
};

export function getThemeReducer(state = themeInitState, action) {
  switch (action.type) {
    case GET_THEME_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_THEME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        selectedTheme: action.payload,
      };
    default:
      return state;
  }
}

export const subjectStatusReducer = (state = initialstate, action) => {
  switch (action.type) {
    case CHANGE_SUBJECT_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_SUBJECT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CHANGE_SUBJECT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const downloadSampleCsvReducer = (state = initialstate, action) => {
  switch (action.type) {
    case DOWNLOAD_SAMPLE_ROSTER_FILE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_SAMPLE_ROSTER_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DOWNLOAD_SAMPLE_ROSTER_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
