import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  tableRoot: {
    width: "100%",
    minHeight: 400,
    padding: 30,
    margin: "20px 0",
    border: "1px solid #e0e0e0",
    borderRadius: 10,
  },
  tableRow: {
    height: 58,
    width: "inherit",
    display: "flex",
    borderBottom: "1px solid #e0e0e0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableCell: {
    width: 100,
    height: 18,
    borderRadius: 4,
  },
}));

function RecentVarifiedSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.tableRoot}>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
      <div className={classes.tableRow}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.tableCell}
        />
      </div>
    </div>
  );
}

export default RecentVarifiedSkeleton;
