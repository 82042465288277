import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Typography from "@material-ui/core/Typography";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderBy } from "../../midbar.action";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useLocation } from "react-router-dom";
import { setPricingPlanAlert } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  actionMain: {
    margin: "10px 0 20px",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 30,
    "& button": {
      borderRadius: "20px",
      fontSize: "14px",
      width: "130px",
      marginRight: "10px",
      padding: "7px 0",
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: 21,
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingRight: 15,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 15,
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      "& button": {
        width: 130,
      },
    },
  },
  formControl: {
    minWidth: 125,
    height: 35,
    background: "transparent",
    position: "relative",
    "& .MuiOutlinedInput-root": {
      borderRadius: 20,
      background: "transparent",
    },
    "& .MuiOutlinedInput-root .MuiInputBase-input": {
      fontSize: 15,
      background: "transparent",
    },
  },
}));

let dropDownOptionsForDate = [
  {
    name: "Ascending",
    value: "older",
  },
  {
    name: "Descending",
    value: "latest",
  },
];

let dropDownOptionsForName = [
  {
    name: "Name(A-Z)",
    value: "a-z",
  },
  {
    name: "Name(Z-A)",
    value: "z-a",
  },
];

const Action = () => {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const history = useHistory();
  const { orderBy, mainContentRef } = useSelector((state) => state.midBar);
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetails = useSelector((state) => state.getUser.data);
  const {
    is_plan_active,
    features: { number_of_issuer_profile = 0, number_of_subjects = 0 } = {},
  } = userDetails;

  const canShowIssuer = is_plan_active || number_of_issuer_profile > 0;
  const canSubject = is_plan_active || number_of_subjects > 0;

  const showPricingAlert = () => {
    dispatch(setPricingPlanAlert(true));
  };

  useEffect(() => {
    if (!location?.state?.showMainContent) return;
    mainContentRef?.current?.classList?.toggle("current");
  }, [location, mainContentRef]);

  const handleAdd = () => {
    mainContentRef?.current?.classList?.toggle("current");
    if (path === "/issuers") {
      if (canShowIssuer) {
        history.push(`${path}/new-issuer`);
      } else {
        showPricingAlert();
      }
    } else if (path === "/credentials") {
      if (canShowIssuer) {
        history.push({
          pathname: `/issuers/new-issuer`,
          state: { showMainContent: true },
        });
      } else {
        showPricingAlert();
      }
    } else if (path === "/subjects") {
      if (canSubject) {
        history.push(`${path}/new-subject`);
      } else {
        showPricingAlert();
      }
    } else if (path === "/badges") {
      history.push(`${path}/new-badge`);
      // if (canSubject) {
      // } else {
      //   showPricingAlert();
      // }
    }
  };

  const handleChange = (event) => {
    if (Boolean(event?.target?.value?.length)) {
      dispatch(changeOrderBy(event.target.value));
    }
  };

  let dropDown = null;
  // let showBtn = canShowIssuer;
  let dropDownOption = dropDownOptionsForDate.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.name}
      </MenuItem>
    );
  });

  let optionListForName = dropDownOptionsForName.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.name}
      </MenuItem>
    );
  });

  let buttonText = "New Issuer";

  if (path.match("/subjects")) {
    // showBtn = canSubject;
    buttonText = "New Subject";
  }
  if (path.match("/badge")) {
    buttonText = "New Badge";
  }
  let actionButton = (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddRoundedIcon />}
      onClick={handleAdd}
    >
      <Typography variant="body1" className={classes.about}>
        {buttonText}{" "}
      </Typography>{" "}
    </Button>
  );

  if (
    path.match("/credentials") ||
    path.match("/issuers") ||
    path.match("/subjects") ||
    path.match("/badges") ||
    path.match("/holders")
  ) {
    dropDown = (
      <FormControl className={classes.formControl} variant="outlined">
        <Select
          value={orderBy}
          onChange={handleChange}
          id="grouped-select"
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <ListSubheader>Sort By Date</ListSubheader>
          {dropDownOption}
          <ListSubheader>Sort By Name</ListSubheader>
          {optionListForName}
        </Select>
      </FormControl>
    );
  }

  if (
    path.match("/activities") ||
    path.match("/settings") ||
    path.match("/holders")
  ) {
    actionButton = null;
  }

  return (
    <>
      {actionButton || dropDown ? (
        <div className={classes.actionMain}>
          {actionButton} {dropDown}
        </div>
      ) : null}
    </>
  );
};
export default React.memo(Action);
