export const REGISTER_BEGIN = "REGISTER_BEGIN";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const REGISTER_EMAIL_VERIFICATION_BEGIN =
  "REGISTER_EMAIL_VERIFICATION_BEGIN";

export const REGISTER_EMAIL_VERIFICATION_SUCCESS =
  "REGISTER_EMAIL_VERIFICATION_SUCCESS";

export const REGISTER_EMAIL_VERIFICATION_FAILURE =
  "REGISTER_EMAIL_VERIFICATION_FAILURE";

export const TOGGLE_EMAIL_VERIFICATION = "TOGGLE_EMAIL_VERIFICATION";
