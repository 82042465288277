import {
  CREATE_ISSUER_BEGIN,
  CREATE_ISSUER_SUCCESS,
  CREATE_ISSUER_FAILURE,
  SET_ISSUER_DETAILS,
  GET_ALL_CREDENTIALS_BEGIN,
  GET_ALL_CREDENTIALS_FAILURE,
  GET_ALL_CREDENTIALS_SUCCESS,
  UPDATE_ISSUER_BEGIN,
  UPDATE_ISSUER_SUCCESS,
  UPDATE_ISSUER_FAILURE,
  CLEAR_ISSUER_DATA,
  DELETE_ISSUER_BEGIN,
  DELETE_ISSUER_SUCCESS,
  DELETE_ISSUER_FAILURE,
  SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN,
  SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS,
  SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE,
  TOGGLE_EMAIL_VERIFICATION_STATUS,
  GET_ISSUER_STATS_BEGIN,
  GET_ISSUER_STATS_SUCCESS,
  GET_ISSUER_STATS_FAILURE,
} from "./issuer.constant";

const initialstate = {
  createIssuer: {
    loading: false,
    error: "",
    response: "",
  },
  issuerDetails: {
    data: {},
  },
  issuerStats: {
    loading: false,
    error: "",
    response: "",
  },
  credentialsRecord: {
    loading: false,
    error: "",
    data: {},
  },
  updateIssuer: {
    loading: false,
    error: "",
    response: "",
  },
  deleteIssuer: {
    loading: false,
    error: "",
    response: "",
  },
  sendVerificationLink: {
    loading: false,
    error: "",
    response: "",
  },
  isEmailVerified: false,
};

export default function issuerReducer(state = initialstate, action) {
  switch (action.type) {
    //Create Issuer
    case CREATE_ISSUER_BEGIN:
      return {
        ...state,
        createIssuer: {
          ...state.createIssuer,
          loading: true,
          error: "",
        },
      };
    case CREATE_ISSUER_SUCCESS:
      return {
        ...state,
        createIssuer: {
          ...state.createIssuer,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case CREATE_ISSUER_FAILURE:
      return {
        ...state,
        createIssuer: {
          ...state.createIssuer,
          loading: false,
          response: "",
          error: action.payload,
        },
      };

    case SET_ISSUER_DETAILS:
      return {
        ...state,
        issuerDetails: {
          ...state.issuerDetails,
          data: action.payload,
        },
      };

    //Get All Credentials
    case GET_ALL_CREDENTIALS_BEGIN:
      return {
        ...state,
        credentialsRecord: {
          ...state.credentialsRecord,
          loading: true,
          error: "",
        },
      };
    case GET_ALL_CREDENTIALS_SUCCESS:
      return {
        ...state,
        credentialsRecord: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case GET_ALL_CREDENTIALS_FAILURE:
      return {
        ...state,
        credentialsRecord: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    //Update Issuer
    case UPDATE_ISSUER_BEGIN:
      return {
        ...state,
        updateIssuer: {
          ...state.updateIssuer,
          loading: true,
        },
      };
    case UPDATE_ISSUER_SUCCESS:
      return {
        ...state,
        updateIssuer: {
          loading: false,
          response: {},
          error: "",
        },
      };
    case UPDATE_ISSUER_FAILURE:
      return {
        ...state,
        updateIssuer: {
          loading: false,
          response: "",
          error: action.payload,
        },
      };
    case GET_ISSUER_STATS_BEGIN:
      return {
        ...state,
        issuerStats: {
          ...state.issuerStats,
          loading: true,
        },
      };
    case GET_ISSUER_STATS_SUCCESS:
      return {
        ...state,
        issuerStats: {
          ...state.issuerStats,
          loading: false,
          data: action.payload,
        },
      };
    case GET_ISSUER_STATS_FAILURE:
      return {
        ...state,
        issuerStats: {
          ...state.issuerStats,
          loading: false,
          error: action.payload,
        },
      };
    //Delete Issuer

    case DELETE_ISSUER_BEGIN:
      return {
        ...state,
        deleteIssuer: {
          ...state.deleteIssuer,
          loading: true,
        },
      };
    case DELETE_ISSUER_SUCCESS:
      return {
        ...state,
        deleteIssuer: {
          ...state.deleteIssuer,
          loading: false,
          response: {},
        },
      };
    case DELETE_ISSUER_FAILURE:
      return {
        ...state,
        deleteIssuer: {
          ...state.deleteIssuer,
          loading: false,
          error: action.payload,
        },
      };

    //Send VERIFICATION_LINK
    case SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN:
      return {
        ...state,
        sendVerificationLink: {
          ...state.sendVerificationLink,
          loading: true,
        },
      };
    case SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationLink: {
          ...state.sendVerificationLink,
          loading: false,
          response: action.payload,
        },
      };
    case SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE:
      return {
        ...state,
        sendVerificationLink: {
          ...state.sendVerificationLink,
          loading: false,
          error: action.payload,
        },
      };
    case TOGGLE_EMAIL_VERIFICATION_STATUS:
      return {
        ...state,
        isEmailVerified: action.payload,
      };

    case CLEAR_ISSUER_DATA: {
      return initialstate;
    }

    default:
      return state;
  }
}
