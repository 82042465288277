import {
  SET_BATCH_DETAILS,
  GET_BATCH_RECORDS_BEGIN,
  GET_BATCH_RECORDS_FAILURE,
  GET_BATCH_RECORDS_SUCCESS,
  CLEAR_LOGS_DATA,
} from "./logs.constant";

const initState = {
  batchDetails: {
    data: {},
  },
  batchRecords: {
    loading: false,
    data: {},
    error: "",
  },
};

export const logsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: {
          data: action.payload,
        },
      };
    case GET_BATCH_RECORDS_BEGIN:
      return {
        ...state,
        batchRecords: {
          ...state.batchRecords,
          loading: true,
          error: "",
        },
      };
    case GET_BATCH_RECORDS_SUCCESS:
      return {
        ...state,
        batchRecords: {
          ...state.batchRecords,
          loading: false,
          error: "",
          data: action.payload,
        },
      };
    case GET_BATCH_RECORDS_FAILURE:
      return {
        ...state,
        batchRecords: {
          ...state.batchRecords,
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case CLEAR_LOGS_DATA:
      return initState;

    default:
      return state;
  }
};
