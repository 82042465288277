import { lazy } from "react";
import authRoles from "core/auth/authRoles";
const About = lazy(() => import("./About"));

const aboutConfig = {
  component: About,
  path: "/about",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: true,
};

export default aboutConfig;
