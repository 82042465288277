import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const ForgetPassword = lazy(() => import("./ForgetPassword"));

const ForgetPasswordConfig = {
  component: ForgetPassword,
  path: "/forgetpassword",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: false,
};

export default ForgetPasswordConfig;
