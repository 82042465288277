export const LIST_FETCH_BEGIN = "LIST_FETCH_BEGIN";
export const LIST_FETCH_SUCCESS = "LIST_FETCH_SUCCESS";
export const LIST_FETCH_FAILURE = "LIST_FETCH_FAILURE";

export const FETCH_MORE_LIST_ITEM_BEGIN = "FETCH_MORE_LIST_ITEM_BEGIN";
export const FETCH_MORE_LIST_ITEM_SUCCESS = "FETCH_MORE_LIST_ITEM_SUCCESS";
export const FETCH_MORE_LIST_ITEM_FAILURE = "FETCH_MORE_LIST_ITEM_FAILURE";

export const UPDATE_MIDBAR_LIST = "UPDATE_MIDBAR_LIST";

export const CHANGE_ORDER_BY = "CHANGE_ORDER_BY";

export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_ROW_PER_PAGE = "CHANGE_ROW_PER_PAGE";
export const SET_MIDBAR_PARENT_REF = "SET_MIDBAR_PARENT_REF";
export const SET_MAIN_CONTENT_REF = "SET_MAIN_CONTENT_REF";
