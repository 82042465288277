import {
  GET_SUBJECT_INFO_BEGIN,
  GET_SUBJECT_INFO_SUCCESS,
  GET_SUBJECT_INFO_FAILURE,
  SET_SUBJECT_INFO,
  GET_CREDENTIAL_LIST_FOR_SUBJECT_BEGIN,
  GET_CREDENTIAL_LIST_FOR_SUBJECT_SUCCESS,
  GET_CREDENTIAL_LIST_FOR_SUBJECT_FAILURE,
  ADD_RECORD_FOR_SUBJECT_BEGIN,
  ADD_RECORD_FOR_SUBJECT_SUCCESS,
  ADD_RECORD_FOR_SUBJECT_FAILURE,
  UPLOAD_RECORD_FOR_SUBJECT_BEGIN,
  UPLOAD_RECORD_FOR_SUBJECT_SUCCESS,
  UPLOAD_RECORD_FOR_SUBJECT_FAILURE,
  GET_ALL_RECORD_FOR_SUBJECT_BEGIN,
  GET_ALL_RECORD_FOR_SUBJECT_SUCCESS,
  GET_ALL_RECORD_FOR_SUBJECT_FAILURE,
  GET_ISSUER_LIST_BEGIN,
  GET_ISSUER_LIST_FAILURE,
  GET_ISSUER_LIST_SUCCESS,
} from "./subjectDetails.constants";

const initialstate = {
  loading: false,
  error: "",
  response: {},
};

export function getSubjectInfoReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_SUBJECT_INFO_BEGIN:
      return { ...state, loading: true };
    case GET_SUBJECT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_SUBJECT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_SUBJECT_INFO:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
}

// Get list of Credential for Specific Subject

const initState = {
  loading: false,
  error: "",
  response: {},
};

export function getCredentialListForSubjectReducer(state = initState, action) {
  switch (action.type) {
    case GET_CREDENTIAL_LIST_FOR_SUBJECT_BEGIN:
      return { ...state, loading: true, error: "", response: {} };
    case GET_CREDENTIAL_LIST_FOR_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: "",
      };
    case GET_CREDENTIAL_LIST_FOR_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: {},
      };
    default:
      return state;
  }
}

// Add Record for Specific Subject

export function addRecordForSubjectReducer(state = initialstate, action) {
  switch (action.type) {
    case ADD_RECORD_FOR_SUBJECT_BEGIN:
      return { ...state, loading: true, error: "" };
    case ADD_RECORD_FOR_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
        error: "",
      };
    case ADD_RECORD_FOR_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Upload Record (CSV file) for specific subject
export function uploadRecordForSubjectReducer(state = initialstate, action) {
  switch (action.type) {
    case UPLOAD_RECORD_FOR_SUBJECT_BEGIN:
      return { ...state, loading: true, error: "" };
    case UPLOAD_RECORD_FOR_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
        error: "",
      };
    case UPLOAD_RECORD_FOR_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function getAllRecordsForSubjectReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_ALL_RECORD_FOR_SUBJECT_BEGIN:
      return { ...state, loading: true };
    case GET_ALL_RECORD_FOR_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_ALL_RECORD_FOR_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function getIssuerListReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_ISSUER_LIST_BEGIN:
      return { ...state, loading: true };
    case GET_ISSUER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_ISSUER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
