import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Pricing = lazy(() => import("./Pricing"));

const PricingConfig = {
  component: Pricing,
  path: "/pricing",
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: true,
};

export default PricingConfig;
