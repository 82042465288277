import {
  CLEAR_ISSUER_DATA,
  CREATE_ISSUER_BEGIN,
  CREATE_ISSUER_FAILURE,
  CREATE_ISSUER_SUCCESS,
  DELETE_ISSUER_BEGIN,
  DELETE_ISSUER_FAILURE,
  DELETE_ISSUER_SUCCESS,
  GET_ALL_CREDENTIALS_BEGIN,
  GET_ALL_CREDENTIALS_FAILURE,
  GET_ALL_CREDENTIALS_SUCCESS,
  GET_ISSUER_STATS_BEGIN,
  GET_ISSUER_STATS_FAILURE,
  GET_ISSUER_STATS_SUCCESS,
  GET_SEARCH_CREDENTIALS_BEGIN,
  GET_SEARCH_CREDENTIALS_FAILURE,
  SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN,
  SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE,
  SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS,
  SET_ISSUER_DETAILS,
  TOGGLE_EMAIL_VERIFICATION_STATUS,
  UPDATE_ISSUER_BEGIN,
  UPDATE_ISSUER_FAILURE,
  UPDATE_ISSUER_SUCCESS,
} from "./issuer.constant";

import {
  changePage,
  updateMidbarList,
} from "components/layout/Midbar/midbar.action";
import { openNotification } from "store/commonActions";
import { headers, http } from "utils";

//Create New Issuer
export const createIssuerBegin = () => ({
  type: CREATE_ISSUER_BEGIN,
});

export const createIssuerSuccess = (payload) => ({
  type: CREATE_ISSUER_SUCCESS,
  payload,
});

export const createIssuerFailure = (payload) => ({
  type: CREATE_ISSUER_FAILURE,
  payload,
});

export const createIssuer =
  (createIssuerData, params, successCB, failureCB) => (dispatch, getState) => {
    const { data, rowPerPage } = getState().midBar;
    dispatch(createIssuerBegin());
    http("post", "/user/issuer", createIssuerData, { headers, params }, true)
      .then(function (response) {
        dispatch(createIssuerSuccess(response.data.data));
        successCB(response.data.data);
        const newMidbarList = [
          ...new Set([response.data.data, ...(data?.list || [])]),
        ];
        let payload = {
          ...data,
          list: newMidbarList,
          total: newMidbarList.length,
          pages: Math.ceil(newMidbarList.length / rowPerPage),
        };
        dispatch(updateMidbarList(payload));
      })
      .catch(function (error) {
        dispatch(createIssuerFailure(error));
        failureCB(error?.response?.data);
      });
  };

export const setIssuerDetails = (payload) => ({
  type: SET_ISSUER_DETAILS,
  payload,
});

// Get All Credentials
const getAllCredentialsBegin = () => ({
  type: GET_ALL_CREDENTIALS_BEGIN,
});
const getAllCredentialsSuccess = (payload) => ({
  type: GET_ALL_CREDENTIALS_SUCCESS,
  payload,
});
const getAllCredentialsFailure = (payload) => ({
  type: GET_ALL_CREDENTIALS_FAILURE,
  payload,
});

export const getAllCredentials = (params) => (dispatch) => {
  dispatch(getAllCredentialsBegin());
  http("get", `/user/issuers/credentials`, null, { headers, params }, true)
    .then((response) => {
      dispatch(getAllCredentialsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getAllCredentialsFailure(err?.response?.data?.message));
    });
};
const getSearchCredentialsBegin = () => ({
  type: GET_SEARCH_CREDENTIALS_BEGIN,
});
const getSearchCredentialsSuccess = (payload) => ({
  type: GET_ALL_CREDENTIALS_SUCCESS,
  payload,
});
const getSearchCredentialsFailure = (payload) => ({
  type: GET_SEARCH_CREDENTIALS_FAILURE,
  payload,
});
export const getSearchCredentials = (params) => (dispatch) => {
  dispatch(getSearchCredentialsBegin());
  http("get", `/search/holder`, null, { headers, params }, true)
    .then((response) => {
      dispatch(getSearchCredentialsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getSearchCredentialsFailure(err?.response?.data?.message));
    });
};
//Update

const updateIssuerBegin = () => ({
  type: UPDATE_ISSUER_BEGIN,
});
const updateIssuerSuccess = (payload) => ({
  type: UPDATE_ISSUER_SUCCESS,
  payload,
});
const updateIssuerFailure = (payload) => ({
  type: UPDATE_ISSUER_FAILURE,
  payload,
});

export const updateIssuer =
  (issuer_id, formData, successCB, failureCB) => (dispatch, getState) => {
    let { data } = getState().midBar;
    dispatch(updateIssuerBegin());
    let body = { ...formData };
    delete body.oldLogo;
    http("put", `/user/issuer/${issuer_id}`, body, { headers }, true)
      .then((response) => {
        dispatch(updateIssuerSuccess(response.data.data));
        successCB();
        let index = data.list.findIndex(
          (element) => element.id === response.data.data.id
        );
        let newArr = [...data.list];
        newArr[index] = response.data.data;
        let payload = {
          ...data,
          list: newArr,
        };
        dispatch(updateMidbarList(payload));
      })
      .catch((err) => {
        dispatch(updateIssuerFailure(err?.response?.data?.message));
        failureCB(err?.response?.data?.message);
      });
  };

//Clear Data

export const clearIssuerData = () => ({
  type: CLEAR_ISSUER_DATA,
});

//Get issuer stats

const getIssuerStatsBegin = () => ({
  type: GET_ISSUER_STATS_BEGIN,
});
const getIssuerStatsSuccess = (payload) => ({
  type: GET_ISSUER_STATS_SUCCESS,
  payload,
});
const getIssuerStatsFailure = (payload) => ({
  type: GET_ISSUER_STATS_FAILURE,
  payload,
});

export const getIssuerStats = (params) => (dispatch, getState) => {
  const { data = [] } = getState().midBar;
  dispatch(getIssuerStatsBegin());
  http("get", `/user/issuer/stats`, null, { headers, params }, true)
    .then((response) => {
      dispatch(getIssuerStatsSuccess(response.data.data));

      let index = data.list.findIndex(
        (element) => element.id === +params.issuer_id
      );

      let issuerDetails = data?.list?.[index] || {};
      let newArr = [...data.list];

      newArr[index] = { ...issuerDetails, ...response.data.data };

      let payload = {
        ...data,
        list: newArr,
      };

      dispatch(updateMidbarList(payload));
    })
    .catch((err) => {
      dispatch(getIssuerStatsFailure(err?.response?.data?.message));
    });
};

//Delete Issuer

const deleteIssuerBegin = () => ({
  type: DELETE_ISSUER_BEGIN,
});
const deleteIssuerSuccess = (paylod) => ({
  type: DELETE_ISSUER_SUCCESS,
  paylod,
});
const deleteIssuerFailure = (paylod) => ({
  type: DELETE_ISSUER_FAILURE,
  paylod,
});

export const deleteSpecificIssuer =
  (issuer_id, successCB) => (dispatch, getState) => {
    const { data, page, rowPerPage } = getState().midBar;
    dispatch(deleteIssuerBegin());
    http("delete", `/user/issuer/${issuer_id}`, null, { headers }, true)
      .then((response) => {
        let newData = data.list.filter((element) => +element.id !== +issuer_id);
        let payload = {
          ...data,
          list: newData,
          total: newData.length,
          pages: Math.ceil(newData.length / rowPerPage),
        };
        dispatch(deleteIssuerSuccess(response.data));
        dispatch(
          openNotification({
            message: "Issuer has been deleted successfully!",
          })
        );
        if (payload.list.length === 0 && page !== payload.pages) {
          dispatch(changePage(page + 1));
        }
        dispatch(updateMidbarList(payload));
        successCB(payload.list);
      })
      .catch((err) => {
        dispatch(deleteIssuerFailure());
      });
  };

//SEND OTP

const sendVerificationLinkToEmailBegin = () => ({
  type: SEND_VERIFICATION_LINK_TO_EMAIL_BEGIN,
});
const sendVerificationLinkToEmailSuccess = (payload) => ({
  type: SEND_VERIFICATION_LINK_TO_EMAIL_SUCCESS,
  payload,
});
const sendVerificationLinkToEmailFailure = (payload) => ({
  type: SEND_VERIFICATION_LINK_TO_EMAIL_FAILURE,
  payload,
});

export const sendVerificationLinkToEmail = (body, successCB) => (dispatch) => {
  dispatch(sendVerificationLinkToEmailBegin());
  http("post", `/issuers/email/confirmation`, body, { headers }, true)
    .then((response) => {
      dispatch(sendVerificationLinkToEmailSuccess(response.data?.data));
      dispatch(
        openNotification({
          message: response?.data?.message,
        })
      );
      successCB();
    })
    .catch((err) => {
      dispatch(
        sendVerificationLinkToEmailFailure(err?.response?.data?.message)
      );
      dispatch(
        openNotification({
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    });
};
export const toggleEmailVerficationStatus = (payload) => ({
  type: TOGGLE_EMAIL_VERIFICATION_STATUS,
  payload,
});
export const getEmailVerificationStatus =
  (email, data) => (dispatch, getState) => {
    let userDetails = getState().getUser.data;
    if (userDetails.email === data.user_email && data.issuer_email === email) {
      dispatch(toggleEmailVerficationStatus(data.email_confirmed));
      if (data.email_confirmed) {
        dispatch(
          openNotification({
            message: "Your email address has been verified",
          })
        );
      }
    }
  };
