import React from "react";
import { makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  sidebarNavItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #d5d5d5",
    background: "#ffffff",
    borderRadius: "20px",
    color: "#747474",
    width: "100%",
    textDecoration: "none",
    [theme.breakpoints.down("xl")]: {
      padding: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      margin: "5px 0",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
      flexDirection: "row",
      border: "none",
      padding: "5px 15px",
    },
    "&:hover": {
      color: "#ffffff",
      background: "#083f85",
      "& .nav-icon-wrap": {
        background: "#ffffff",
        "& i": {
          color: "#083f85",
        },
      },
    },
  },
  activeLink: {
    color: "#ffffff",
    background: "#083f85",
    transition: "all 2s ease",
    "& .nav-icon-wrap": {
      background: "#ffffff",
      "& i": {
        color: "#083f85",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "7px 15px",
      borderRadius: 0,
      background: "#f6f6f6",
      color: "#083f85",
      borderRight: "3px solid",
      "& .nav-icon-wrap": {
        background: "#083f85",
        "& i": {
          color: "#fff",
        },
      },
    },
    "&::after": {
      [theme.breakpoints.down("xl")]: {
        right: "-15px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("xl")]: {
        right: "-18px",
      },
      content: `' '`,
      position: "absolute",
      backgroundColor: "#083f85",
      width: 3,
      height: 25,
      top: "50%",
      transform: "translate(0, -50%)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      left: "97%",
      top: "50%",
      transform: "translate(0, -50%)",
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      [theme.breakpoints.down("xl")]: {
        borderLeft: "8px solid #083f85",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("xl")]: {
        borderLeft: "12px solid #083f85",
      },
    },
  },
  iconWrap: {
    height: "35px",
    width: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#f6f6f6",
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 6px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      background: "#083f85",
    },
  },
  sidebarNavItemIcon: {
    lineHeight: 0,
    "& svg": {
      height: "auto",
      width: "22px",
      color: "#083f85",
    },
  },
  sidebarNavItemText: {
    fontSize: "11px",
    fontFamily: "Roboto",
    fontWeight: "400",
    display: "Block",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      fontWeight: 500,
      marginLeft: 10,
    },
  },
}));

function SidebarNavItem({ path, itemName, icon, exact }) {
  const classes = useStyle();

  return (
    <NavLink
      className={classes.sidebarNavItem}
      to={path}
      exact={exact}
      activeClassName={classes.activeLink}
    >
      <div className={`${classes.iconWrap} nav-icon-wrap`}>
        <i className={`${classes.sidebarNavItemIcon}`}>{icon}</i>
      </div>
      <span className={classes.sidebarNavItemText}>{itemName}</span>
    </NavLink>
  );
}

export default React.memo(SidebarNavItem);
