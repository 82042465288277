import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Issuers = lazy(() => import("./Issuers"));

const issuersConfig = {
  component: Issuers,
  path: "/issuers",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: true,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
  // canShow: "userDetails.is_plan_active || userDetails.is_issuer",
};

export default issuersConfig;
